<template>
  <div>
    <v-container>
      <p>Oops! Cette page n'est pas accessible.</p>
      <h4>
        <v-btn text to="/france">ReVote</v-btn>
      </h4>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "not-found",
};
</script>
